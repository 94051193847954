/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React, { MouseEventHandler, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAlert } from 'react-alert';

import { Divider, Paper, Popover } from '@mui/material';

import { useTheme, Icon } from '@savant-components/theme';
import {
  Spacer,
  // Avatar,
  Button,
  FlexFiller,
  Search,
  useAsync,
  ClickableIcon,
  Loader,
} from '@savant-components/basic';
import { OrganizationInfo } from '@savant-components/catalog';
import { Organization, TabSession, Workspace } from '../../types';
import {
  getAccessibleOrganizations,
  getAccessibleWorkspaces,
  switchOrg,
  switchWorkspace,
} from '../../services/session';
import { handleError } from '../../services/client';
import { reloadTabSession } from '../../hooks/auth';
import { useCustomEventListener } from 'react-custom-events';
import events from '../../events';
import { AxiosError } from 'axios';

const SwitchOrg = ({
  organizations: allOrgs,
  currentOrgId,
  switchOrgLabel,
  onGoBack,
  onSwitchToOrg,
}: {
  switchOrgLabel: string;
  currentOrgId: string;
  organizations: Organization[];
  onGoBack: () => void;
  onSwitchToOrg: (org: Organization) => void;
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState<string>('');

  const organizations = useMemo(() => {
    let orgs: Organization[];
    if (search) {
      orgs = allOrgs.filter(org => org.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    } else {
      orgs = [...allOrgs];
    }
    orgs.sort((o1, o2) => {
      return o1.name.toLowerCase().localeCompare(o2.name.toLowerCase());
    });
    return orgs;
  }, [search, allOrgs]);

  const onClickOrg = (org: Organization) => {
    if (org.id !== currentOrgId) {
      onSwitchToOrg(org);
    }
  };

  const style = css`
    border-radius: 8px;
    border: 1px solid ${theme.colors.o2};
    width: 400px;
    & > .header {
      display: flex;
      align-items: center;
      margin: 16px 24px 8px 8px;
      text-transform: uppercase;
    }
    & > .content {
      margin: 16px 24px;
      > .searh {
        width: 100%;
      }
      > .org-list {
        > .org-item {
          margin-top: 4px;
          padding: 4px;
          display: flex;
          align-items: center;
          border: 1px solid ${theme.colors.b6};
          border-radius: 8px;
          cursor: pointer;
          :hover {
            border: 1px solid ${theme.colors.o2};
            background: ${theme.colors.b7};
          }
        }
        > .current-org {
          cursor: default;
          background: ${theme.colors.b4};
          :hover {
            background: ${theme.colors.b4};
          }
        }
      }
    }
  `;
  return (
    <div css={style}>
      <div className="header">
        <ClickableIcon color={theme.colors.i2} size={24} icon={['las', 'angle-left']} onClick={onGoBack} />
        <span className="text-t8">{switchOrgLabel}</span>
      </div>
      <Divider />
      <div className="content">
        <div className="searh">
          <Search search={search} setSearch={setSearch} />
        </div>
        <div className="org-list">
          {organizations.map((org, idx) => {
            let clzName = 'org-item';
            if (org.id === currentOrgId) {
              clzName += ' current-org';
            }
            return (
              <div key={`org-${idx}`} className={clzName} onClick={() => onClickOrg(org)}>
                <OrganizationInfo organization={org} showDomain />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SwitchWorkspace = ({
  workspaces: allWorkspaces,
  currentWorkspaceId,
  chooseWorkspaceLabel,
  onSwitchToWorkspace,
}: {
  chooseWorkspaceLabel: string;
  currentWorkspaceId: string;
  workspaces: Workspace[];
  onSwitchToWorkspace: (ws: Workspace) => void;
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState<string>('');

  const workspaces = useMemo(() => {
    let spaces: Workspace[];
    if (search) {
      spaces = allWorkspaces.filter(org => org.name.toLowerCase().indexOf(search.toLowerCase()) >= 0);
    } else {
      spaces = [...allWorkspaces];
    }
    spaces.sort((o1, o2) => {
      if (o1.id === 'general') {
        return -1;
      } else if (o2.id === 'general') {
        return 1;
      } else {
        return o1.name.toLowerCase().localeCompare(o2.name.toLowerCase());
      }
    });
    return spaces;
  }, [search, allWorkspaces]);

  const onClickWorkspace = (workspace: Workspace) => {
    if (workspace.id !== currentWorkspaceId) {
      onSwitchToWorkspace(workspace);
    }
  };

  const style = css`
    border-radius: 8px;
    // border: 1px solid ${theme.colors.o2};
    width: 300px;
    .ws-title {
      max-width: 275px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & > .header {
      display: flex;
      align-items: center;
      margin: 16px 24px 8px 8px;
      // text-transform: uppercase;
    }
    & > .content {
      width: 100%;
      > .searh {
        width: 100%;
        padding: 0 8px;
      }
      > .ws-list {
        > .ws-item {
          margin-top: 4px;
          margin-left: 8px;
          margin-right: 8px;
          padding: 4px;
          height: 48px;
          display: flex;
          align-items: center;
          border: 1px solid ${theme.colors.b6};
          border-radius: 8px;
          cursor: pointer;
          :hover {
            border: 1px solid ${theme.colors.o2};
            background: ${theme.colors.b7};
          }
          > .ws-name {
            margin-left: 8px;
          }
        }
        > .current-ws {
          cursor: default;
          background: ${theme.colors.b4};
          :hover {
            background: ${theme.colors.b4};
          }
        }
      }
    }
  `;
  return (
    <div css={style}>
      <div className="header">
        <span className="text-t8">{chooseWorkspaceLabel}</span>
      </div>
      <div className="content">
        <div className="searh">
          <Search search={search} setSearch={setSearch} />
        </div>
        <div className="ws-list">
          {workspaces.map((workspace, idx) => {
            let clzName = 'ws-item';
            if (workspace.id === currentWorkspaceId) {
              clzName += ' current-ws';
            }
            return (
              <div key={`ws-${idx}`} className={clzName} onClick={() => onClickWorkspace(workspace)}>
                <div className="ws-name">
                  {workspace.name && (
                    <div className="text-t5 ws-title" title={workspace.name}>
                      {workspace.name}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const OrgMenu = ({ tab }: { tab: TabSession }): JSX.Element => {
  const theme = useTheme();
  const intl = useIntl();
  const alert = useAlert();
  const switchOrgLabel = intl.formatMessage({ id: 'header.switchOrg', defaultMessage: 'Switch organization' });
  const chooseWorkspaceLabel = intl.formatMessage({ id: 'header.chooseWorkspace', defaultMessage: 'Choose workspace' });

  const org = tab.organization || ({} as Organization);
  const workspace = tab.workspace;
  const [viewSwitchOrg, setViewSwitchOrg] = useState<boolean>();

  const {
    value: orgs,
    error: orgsError,
    status: orgsStatus,
  } = useAsync<Organization[], AxiosError>(getAccessibleOrganizations, true);
  useEffect(() => {
    if (orgsError) {
      handleError(orgsError, alert);
    }
  }, [orgsError]);
  const isFetchingOrgs = orgsStatus !== 'success' && orgsStatus !== 'error';
  const [isSwitchingOrg, setIsSwitchingOrg] = useState<boolean>();
  const onSwitchToOrg = (toOrg: Organization) => {
    setViewSwitchOrg(false);
    setIsSwitchingOrg(true);
    switchOrg(toOrg.id)
      .then(tab => {
        reloadTabSession(intl.locale, tab, false);
      })
      .catch(err => handleError(err, alert))
      .finally(() => setTimeout(() => setIsSwitchingOrg(false), 1000));
  };

  const {
    value: workspaces,
    error: workspacesError,
    status: workspacesStatus,
    execute: workspacesExecute,
  } = useAsync<Workspace[], AxiosError>(getAccessibleWorkspaces, true);
  useEffect(() => {
    if (workspacesError) {
      handleError(workspacesError, alert);
    }
  }, [workspacesError]);
  const isFetchingWorkspaces = workspacesStatus !== 'success' && workspacesStatus !== 'error';
  const [isSwitchingWorkspace, setIsSwitchingWorkspace] = useState<boolean>();
  const onSwitchToWorkspace = (toWorkspace: Workspace) => {
    setIsSwitchingWorkspace(true);
    switchWorkspace(toWorkspace.id)
      .then(tab => {
        reloadTabSession(intl.locale, tab, false);
      })
      .catch(err => handleError(err, alert));
  };

  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const openUserMenu = Boolean(userMenuAnchorEl);
  const handleClickUserAvatar: MouseEventHandler<HTMLDivElement> = event => {
    setUserMenuAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setViewSwitchOrg(false);
    setUserMenuAnchorEl(null);
  };

  useCustomEventListener(events.NEW_WORKSPACE, () => {
    workspacesExecute();
  });
  useCustomEventListener(events.ARCHIVE_WORKSPACE, () => {
    workspacesExecute();
  });

  const orgName = org.name || org.domain;
  const name = workspace ? (workspace.id === 'general' ? orgName : workspace.name) : orgName;
  const title = workspace ? `${org.name} - ${workspace.name}` : org.name;

  const btnStyle = css`
    border-radius: 8px;
    background: ${openUserMenu ? theme.colors.b4 : theme.colors.b6};
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0 12px;
    height: 36px;

    :hover {
      background: ${theme.colors.b7};
    }
  `;

  const menuStyle = css`
    border-radius: 8px;
    border: 1px solid ${theme.colors.o2};
    width: 300px;
    & > .loader {
      height: 200px;
    }
    & > .user-menu {
      margin-top: 16px;
      margin-bottom: 16px;
      & > .user-info {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      & > .user-btns {
        margin-top: 8px;
        display: flex;
      }
    }
  `;

  return (
    <React.Fragment>
      <div css={btnStyle} data-testid={'org-profile'} title={title} onClick={handleClickUserAvatar}>
        <span>{name}</span>
        <Icon
          sx={{
            fontSize: '16px',
          }}
          fontSize="inherit"
          name="ExpandMore"
        />
      </div>
      <Popover
        id={'org-profile'}
        open={openUserMenu}
        anchorEl={userMenuAnchorEl}
        onClose={handleCloseUserMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {viewSwitchOrg && orgs ? (
          <SwitchOrg
            switchOrgLabel={switchOrgLabel}
            currentOrgId={org.id}
            organizations={orgs}
            onGoBack={() => setViewSwitchOrg(false)}
            onSwitchToOrg={onSwitchToOrg}
          />
        ) : (
          <Paper css={menuStyle}>
            <div className="user-menu">
              <div className="user-info">
                {org.logoUrl ? (
                  // <Avatar name={org.name} img={org.logoUrl} size={24} />
                  <img src={org.logoUrl} width="32" height="32" />
                ) : (
                  <Icon fontSize="large" name="Domain" />
                )}
                <Spacer direction="vertical" size="8px" />
                {org.name && (
                  <div data-testid="org-name" className="text-t5 text-nowrap">
                    {org.name}
                  </div>
                )}
                {org.domain && org.domain !== org.name && (
                  <div data-testid="org-domain" className="text-t10">
                    {org.domain}
                  </div>
                )}
              </div>
              {!isFetchingOrgs && (orgs || []).length > 1 && (
                <div className="user-btns">
                  <FlexFiller />
                  <Button
                    design="secondary"
                    label={switchOrgLabel}
                    loading={isSwitchingOrg}
                    disabled={isSwitchingOrg}
                    onClick={() => {
                      setViewSwitchOrg(true);
                    }}
                  />
                  <FlexFiller />
                </div>
              )}
              <Spacer direction="vertical" size="8px" />
              {isFetchingWorkspaces || isSwitchingWorkspace ? (
                <React.Fragment>
                  <Spacer direction="vertical" size="8px" />
                  <Loader size={100} />
                  <Spacer direction="vertical" size="8px" />
                </React.Fragment>
              ) : (
                workspace &&
                (workspaces || []).length > 1 && (
                  <SwitchWorkspace
                    workspaces={workspaces || []}
                    currentWorkspaceId={workspace.id}
                    chooseWorkspaceLabel={chooseWorkspaceLabel}
                    onSwitchToWorkspace={onSwitchToWorkspace}
                  />
                )
              )}
            </div>
          </Paper>
        )}
      </Popover>
    </React.Fragment>
  );
};

export default OrgMenu;
