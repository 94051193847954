import { Automation, Engine, FeatureFlagContextModel, FlowState, getGraph } from '@savant-components/builder';
import { useContext, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { useIntl } from 'react-intl';
import { handleError } from '../services/client';
import { updateRecipe, updateRecipeMetadata } from '../services/recipes';
import { useBuilderStateCache, useFlow, useLatestSample } from './flow';
import { useBizAppConfig } from './useBizAppConfig';
import { Recipe } from '@savant-components/catalog';
import { AnalysisContext } from '../components/app/AnalysisDetailLayout';
import { useDynamicOptionProvider } from './useDynamicOptionProvider';
import { listAIProviderConnections } from '../services/connection';
import { Handlers } from '@savant-components/builder/build/Builder/BuilderProps';
import { useEngine } from '../components/app/useEngine';
import { useFeatureFlags } from '../components/app/useFeatureFlags';
import { IntlShape } from 'react-intl/src/types';

interface UseFlowBuilderState {
  automation: Automation;
  flow: FlowState;
  isSavingRecipe: boolean;
  isLoading: boolean;
  recipeName: string;
  recipeVersion: string;
}

interface Created {
  engine: Engine;
  intl: IntlShape;
  state: UseFlowBuilderState;
  builderHandlers: Handlers;
  featureFlags: FeatureFlagContextModel;
}

export const useFlowBuilder = (recipeId: string): Created => {
  const ctx = useContext(AnalysisContext);
  const recipe = ctx.recipe;
  const [isSavingRecipe, setIsSavingRecipe] = useState<boolean>(false);
  const alert = useAlert();
  const intl = useIntl();
  const {
    isLoading,
    flow,
    recipeVersion,
    recipeName,
    automation,
    setFlow,
    onNodeChange,
    onSaveGraph,
    startTestRun,
    startRunNow,
    askNLP,
    giveNLPFeedback,
  } = useFlow(recipe, {
    submitted: useMemo(() => intl.formatMessage({ id: 'builder.submitted' }), [intl]),
  });
  return {
    state: {
      automation,
      flow,
      isSavingRecipe,
      isLoading,
      recipeName,
      recipeVersion,
    },
    builderHandlers: {
      onNodeStateChange: onNodeChange,
      onSaveRecipe: async (): Promise<void> => {
        setIsSavingRecipe(true);
        const { nodes, parameters } = await getGraph();
        updateRecipe(recipeId, {
          ...flow,
          id: recipeId,
          name: recipeName,
          version: recipeVersion,
          nodes,
          parameters,
        })
          .catch(err => handleError(err, alert))
          .then(() => {
            alert.success(intl.formatMessage({ id: 'app.recipe.saveRecipe' }));
          })
          .finally(() => setIsSavingRecipe(false));
      },
      onSaveGraph,
      // onToggleAutomate: () => {
      saveRecipeMetadata: async (recipe2: Recipe): Promise<Recipe> => {
        return updateRecipeMetadata(
          recipe2.id,
          {
            name: recipe2.name,
            description: recipe2.description,
            tags: recipe2.tags,
            parameters: recipe2.parameters,
          } as Recipe,
          false, // update paramters with this request
        )
          .then(async resp => {
            await ctx.updateRecipeName(recipe2.name);
            setFlow(recipe2);
            return resp;
          })
          .catch(err => {
            handleError(err, alert);
            return {
              name: recipe?.name,
              description: recipe?.description,
              tags: recipe?.tags,
            } as Recipe;
          });
      },
      startBotRun: startRunNow,
      startTestRun,
      // TODO: easy methods to remove from the builder and add to the engine
      ...useBuilderStateCache(recipeId),
      ...useLatestSample(recipeId),
      ...useBizAppConfig({ recipeId, recipeVersion, recipeName }),
      ...useDynamicOptionProvider(),
      askNLP,
      giveNLPFeedback,
      listAIProviderConnections,
    },
    ...useEngine(recipeId),
    intl,
    featureFlags: useFeatureFlags(),
  };
};
