/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useMemo } from 'react';
import { useAlert, AlertContainer as AlertManager } from 'react-alert';
import queryString from 'query-string';

import { DataFrame, WizardStep, ConnectionInfo, LogicalDataType } from '@savant-components/basic';
import { Compression, IDataPullWindow } from '@savant-components/catalog';
import {
  Source,
  AggregatedAddSourceState,
  AggregatedUploadFileSourceState,
  Connection,
  StructuredSourceService,
  ObjectPath,
  ObjectTree,
  Connector,
  ObjectSchema,
  FilePath,
  Recipe,
  QLQuery,
  FileType,
  SourceConfigType,
  FileSystemConfigType,
} from '@savant-components/catalog';
import {
  createSource,
  listSources,
  deleteSource,
  CreateSourceRequest,
  getSource,
  getSample,
  evictSourceCache,
  refreshSample,
  updateSourceName,
  getSourceObjectSchema,
} from '../services/source';
import {
  getObjectTree,
  getSampleAsync,
  getDefaultSelection,
  getConnection,
  updateConnection,
  getObjectSchema,
  listConnections,
  getFileSystemSample,
  parseFileURL,
  parseFolderURL,
  validateFilePattern,
  getTabs as getTabsViaApi,
  getConnectionInfo as getConnectionInfoViaApi,
  UpdateConnectionRequest,
  getQuerySampleAsync,
  getObjectSchemaByQuery,
} from '../services/connection';
import {
  getObjectTree as getFileObjectTree,
  getSample as getFileSample,
  getObjectSchema as getFileSchema,
} from '../services/upload';
import { handleError } from '../services/client';
import {
  getAddSourceWizardContext,
  getRedirectState,
  removeAddSourceWizardContext,
  removeRedirectState,
  saveAddSourceWizardContext,
} from '../services/storage';
import { useIntl } from 'react-intl';
import { getDependingRecipesBySourceId } from '../services/recipes';
import { AxiosError } from 'axios';

const getConfigService = ({ alert }: { alert: AlertManager }): StructuredSourceService => {
  return {
    getQLSchema: async (connectionId: string, query: QLQuery): Promise<ObjectSchema> => {
      if (connectionId !== undefined && query?.query !== undefined) {
        try {
          return await getObjectSchemaByQuery(connectionId, query.query);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return undefined as unknown as ObjectSchema;
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as ObjectSchema);
        });
      }
    },
    getQLSample: async (connectionId: string, query: QLQuery): Promise<DataFrame> => {
      if (connectionId !== undefined && query?.query !== undefined) {
        try {
          return await getQuerySampleAsync(connectionId, query.query);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return undefined as unknown as DataFrame;
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as DataFrame);
        });
      }
    },
    getObjectTree: async (connectionId: string): Promise<ObjectTree> => {
      if (connectionId !== undefined) {
        try {
          return await getObjectTree(connectionId, 'READ');
        } catch (err) {
          handleError(err as AxiosError, alert);
          return {
            catalogs: [],
          };
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as ObjectTree);
        });
      }
    },
    getObjectSchema: async (connectionId: string, objectPath: ObjectPath): Promise<ObjectSchema> => {
      if (connectionId !== undefined && objectPath?.table !== undefined) {
        try {
          return await getObjectSchema(connectionId, objectPath, 'READ');
        } catch (err) {
          handleError(err as AxiosError, alert);
          return undefined as unknown as ObjectSchema;
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as ObjectSchema);
        });
      }
    },
    getSourceObjectSchema: async (sourceId: string): Promise<ObjectSchema> => {
      if (sourceId !== undefined) {
        try {
          return await getSourceObjectSchema(sourceId);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return undefined as unknown as ObjectSchema;
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as ObjectSchema);
        });
      }
    },
    getSample: async (
      connectionId: string,
      config: SourceConfigType,
      overwrittenTypes: { [key: string]: LogicalDataType },
      dataPullWindow?: IDataPullWindow,
    ): Promise<DataFrame> => {
      if (connectionId !== undefined && config?.table !== undefined) {
        try {
          return await getSampleAsync(connectionId, config, overwrittenTypes, dataPullWindow);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return undefined as unknown as DataFrame;
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as DataFrame);
        });
      }
    },
    getFileObjectTree: async (fileId: string, fileType: FileType): Promise<ObjectTree> => {
      if (fileId !== undefined) {
        try {
          return await getFileObjectTree(fileId, fileType);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return undefined as unknown as ObjectTree;
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as ObjectTree);
        });
      }
    },
    getFileSample: async (
      config: SourceConfigType,
      overwrittenTypes: { [key: string]: LogicalDataType },
    ): Promise<DataFrame> => {
      if (config.fileId !== undefined && config?.table !== undefined) {
        try {
          return await getFileSample(config, overwrittenTypes);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return undefined as unknown as DataFrame;
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as DataFrame);
        });
      }
    },
    getFileObjectSchema: async (fileId: string, fileType: FileType, objectPath: ObjectPath): Promise<ObjectSchema> => {
      if (fileId !== undefined) {
        try {
          return await getFileSchema(
            fileId,
            fileType,
            objectPath.table,
            objectPath.skipRows,
            objectPath.tabPattern,
            objectPath.delimiter,
            objectPath.qualifier,
            objectPath.charset,
            objectPath.useTabPattern,
            objectPath.headerless,
          );
        } catch (err) {
          handleError(err as AxiosError, alert);
          return undefined as unknown as ObjectSchema;
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as ObjectSchema);
        });
      }
    },
    getDefaultSelection: async (connectionId: string, objectPath: ObjectPath): Promise<string[]> => {
      if (connectionId !== undefined && objectPath?.table !== undefined) {
        try {
          return await getDefaultSelection(connectionId, objectPath);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return [];
        }
      } else {
        return new Promise(resolve => {
          resolve([]);
        });
      }
    },
    getFileSystemSample: async (
      fileId: string,
      filePath: FilePath,
      overwrittenTypes: { [key: string]: LogicalDataType },
      compression: Compression,
    ): Promise<DataFrame> => {
      if (fileId !== undefined && filePath.fileType !== undefined) {
        try {
          return await getFileSystemSample(fileId, filePath, overwrittenTypes, compression);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return undefined as unknown as DataFrame;
        }
      } else {
        return new Promise(resolve => {
          resolve(undefined as unknown as DataFrame);
        });
      }
    },
    parseFileURL: async (fileId: string, fileUrl: string): Promise<string | AxiosError> => {
      if (fileId !== undefined && fileUrl !== undefined) {
        try {
          return await parseFileURL(fileId, fileUrl);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return err as AxiosError;
        }
      } else {
        return new Promise(resolve => {
          resolve(fileUrl);
        });
      }
    },
    parseFolderURL: async (fileId: string, folderUrl: string): Promise<string> => {
      if (fileId !== undefined && folderUrl !== undefined) {
        try {
          return await parseFolderURL(fileId, folderUrl);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return folderUrl;
        }
      } else {
        return new Promise(resolve => {
          resolve(folderUrl);
        });
      }
    },
    validateFilePattern: async (fileId: string, folderUrl: string, filePattern: string): Promise<string> => {
      if (fileId !== undefined && folderUrl !== undefined && filePattern !== undefined) {
        try {
          return await validateFilePattern(fileId, folderUrl, filePattern);
        } catch (err) {
          handleError(err as AxiosError, alert);
          return '';
        }
      } else {
        return new Promise(resolve => {
          resolve('');
        });
      }
    },
    getTabs: async (connectionId: string, config: FileSystemConfigType): Promise<string[]> => {
      if (connectionId !== undefined && config?.fileURL !== undefined) {
        const request = {
          extension: config?.fileType,
          fileUrl: !config?.isMultiple ? config?.fileURL : '',
          folderUrl: config?.isMultiple ? config?.folderURL : '',
          filePattern: config?.isMultiple ? config?.filePattern : '',
          isMultiple: config?.isMultiple,
          useLatestFile: config?.useLatestFile,
        };
        return getTabsViaApi(connectionId, request).catch(err => {
          handleError(err, alert);
          return [];
        });
      } else {
        return new Promise(resolve => {
          resolve([]);
        });
      }
    },
    getSourceById: async (id: string): Promise<Source | void> => {
      return await getSource(id)
        .then(resp => resp)
        .catch(err => {
          handleError(err, alert);
        });
    },
  };
};

interface IUseSourceDetail {
  isLoading: boolean;
  source: Source;
  isFileSystem: boolean;
  connection: Connection;
  sample: DataFrame;
  getConnectionInfo: (connectionId: string) => Promise<ConnectionInfo>;
  onUpdateName: (changedName: string) => Promise<string>;
}

export function useSourceDetail(sourceId: string): IUseSourceDetail {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFileSystem, setIsFileSystem] = useState<boolean>(false);
  const [source, setSouce] = useState<Source>();
  const [connection, setConnection] = useState<Connection>();
  const [sample, setSample] = useState<DataFrame>();
  const alert = useAlert();
  useEffect(() => {
    if (sourceId !== source?.id && !isLoading) {
      setIsLoading(true);
      if (source) {
        setSouce(undefined);
        setConnection(undefined);
      }
      getSource(sourceId)
        .then(src => {
          return getSample(sourceId)
            .then(sample => {
              setSample(sample);
            })
            .finally(() => {
              const config = {
                ...src?.config,
                cursorField: src?.config?.updateCursor,
              };
              setSouce({
                ...src,
                config,
              } as Source);
              setIsFileSystem(src?.config?.['type'] === 'filesystem');
              setConnection({
                id: src.connectionId as string,
                name: src.connectionName as string,
                type: src.type,
              } as any);
            });
        })
        .catch(err => handleError(err, alert))
        .finally(() => setIsLoading(false));
    }
  }, [sourceId, isLoading, source?.id]);

  const getConnectionInfo = (connectionId: string): Promise<ConnectionInfo> => {
    return getConnectionInfoViaApi(connectionId).catch(err => {
      handleError(err, alert);
      return undefined as unknown as ConnectionInfo;
    });
  };

  const onUpdateName = async (newName: string): Promise<string> => {
    return updateSourceName(sourceId, newName).then(() => {
      setSouce({
        ...(source as Source),
        name: newName,
      });
      return newName;
    });
  };

  return {
    isLoading,
    source: source as Source,
    isFileSystem,
    connection: connection as Connection,
    sample: sample as DataFrame,
    getConnectionInfo,
    onUpdateName,
  };
}

export interface IUseSelectSource {
  getSourcesOfType: (connector: string) => Promise<Source[]>;
}

export function useSelectSource(): IUseSelectSource {
  const alert = useAlert();
  const getSourcesOfType = (connector: string): Promise<Source[]> => {
    return listSources(connector).catch(err => {
      handleError(err, alert);
      return [];
    });
  };
  return {
    getSourcesOfType,
  };
}

export interface IUploadFileSource {
  isLoading: boolean;
  confirmAddSource: (state: AggregatedUploadFileSourceState) => Promise<Source | undefined>;
  configService: StructuredSourceService;
}

export function useUploadFileSource(): IUploadFileSource {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [version, setVersion] = useState<number>(0);

  // let qString = undefined;
  // if (typeof window !== 'undefined') {
  //   qString = window.location.search;
  // }

  const confirmAddSource = async (state: AggregatedUploadFileSourceState): Promise<Source | undefined> => {
    setIsLoading(true);
    const connector = state.configure.fileType;
    let req: CreateSourceRequest | undefined = undefined;
    if (connector === 'CSV' || connector === 'EXCEL' || connector === 'BLOB') {
      req = buildFileSourceReq(state);
    }
    if (req) {
      try {
        const source = await createSource(req);
        setVersion(version + 1);
        return source;
      } catch (err) {
        handleError(err as AxiosError, alert);
        throw err;
      }
    }
  };

  return {
    isLoading,
    confirmAddSource,
    configService: getConfigService({ alert }),
  };
}

export interface IAddSource {
  isLoading: boolean;
  restoreSteps: WizardStep[];
  initialConnection?: Connection;
  initialConnector?: Connector;
  confirmAddSource: (state: AggregatedAddSourceState) => Promise<Source | undefined>;
  onConfirmNewConnection: (state: AggregatedAddSourceState) => void;
  getConnectionsByType: (type: string) => Promise<Connection[]>;
  configService: StructuredSourceService;
  getAllConnections: () => Promise<Connection[]>;
  sourceWizard?: Source;
}

export function useAddSource(): IAddSource {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [version, setVersion] = useState<number>(0);
  const [initialConnection, setInitialConnection] = useState<Connection>();
  const [initialConnector, seInitialConnector] = useState<Connector>();
  const [sourceWizard, setSourceWizard] = useState<Source>();

  const storedSourceId = getAddSourceWizardContext();

  let qString = undefined as unknown as string;
  if (typeof window !== 'undefined') {
    qString = window.location.search;
  }

  const restoreSteps = useMemo(() => {
    if (qString) {
      const parsed = queryString.parse(qString);
      if (parsed.restoreState !== undefined) {
        const error_detail = parsed.error_detail;
        if (error_detail) {
          const redirectState = getRedirectState();
          return redirectState.map((step: { id: string; state: any }) => {
            if (step.id === 'authenticate') {
              return {
                ...step,
                state: {
                  ...step.state,
                  auth: {
                    error: error_detail,
                  },
                },
              };
            } else {
              return step;
            }
          });
        } else {
          const connectionId = parsed.connectionId;
          const externalId = parsed.externalId;
          const externalName = parsed.externalName;
          const expiresAt = parseInt(parsed.expiresAt as string);
          const redirectState = getRedirectState();
          return redirectState.map((step: { id: string; state: any }) => {
            if (step.id === 'authenticate') {
              return {
                ...step,
                state: {
                  ...step.state,
                  auth: {
                    id: connectionId,
                    externalId,
                    externalName,
                    expiresAt,
                  },
                },
              };
            } else {
              return step;
            }
          });
        }
      } else {
        removeRedirectState();
        return undefined;
      }
    }
  }, [qString]);

  useEffect(() => {
    if (qString) {
      const parsed = queryString.parse(qString);
      setIsLoading(true);
      if (parsed.connectionId) {
        getConnection(parsed.connectionId as string).then(connection => {
          setInitialConnection(connection);
          setIsLoading(false);
        });
      } else if (parsed.connector) {
        seInitialConnector(parsed.connector as Connector);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [qString]);

  const getConnectionsByType = async (type: string): Promise<Connection[]> => {
    try {
      const conns = await listConnections({
        connector: type,
        excludeWriteOnly: true,
        excludeDbt: true,
      });
      return [...conns];
    } catch (err) {
      handleError(err as AxiosError, alert);
      return [];
    }
  };

  const getAllConnections = async (): Promise<Connection[]> => {
    try {
      const conns = await listConnections({
        excludeWriteOnly: true,
      });
      return [...conns];
    } catch (err) {
      handleError(err as AxiosError, alert);
      return [];
    }
  };

  const onConfirmNewConnection = (state: AggregatedAddSourceState): void => {
    const { id } = state.authenticate.auth;
    const { name, description } = state.authenticate;
    const req: UpdateConnectionRequest = {
      name: name as string,
      description: description as string,
    };
    updateConnection(id, req).catch(err => {
      handleError(err, alert);
    });
  };

  const confirmAddSource = async (state: AggregatedAddSourceState): Promise<Source | undefined> => {
    setIsLoading(true);
    const connector = state.connect.selectedType || state.connect.type;
    let req: CreateSourceRequest | undefined;
    if (connector === 'csv' || connector === 'excel') {
      req = buildFileSourceReq(state);
    } else {
      if (state.authenticate?.auth?.id) {
        const connectionId = state.authenticate.auth.id;
        try {
          await updateConnection(connectionId, {
            name: state.authenticate.name as string,
            description: state.authenticate.description as string,
          });
        } catch (err) {
          handleError(err as AxiosError, alert);
          throw err;
        }
      }
      req = buildGeneralSourceReq(state);
    }
    if (req) {
      try {
        const source = await createSource(req);
        setVersion(version + 1);
        return source;
      } catch (err) {
        handleError(err as AxiosError, alert);
        throw err;
      }
    }
  };

  const getSourceData = async () => {
    if (!storedSourceId?.startsWith('edit-recipe')) {
      setIsLoading(true);
      await getSource(storedSourceId)
        .then(source => {
          setIsLoading(false);
          setSourceWizard(source);
        })
        .catch(err => {
          setIsLoading(false);
          handleError(err, alert);
        });
    }
  };

  useEffect(() => {
    getSourceData();
  }, []);

  return {
    isLoading,
    initialConnection,
    initialConnector,
    confirmAddSource,
    onConfirmNewConnection,
    getConnectionsByType,
    configService: getConfigService({ alert }),
    getAllConnections,
    restoreSteps,
    sourceWizard,
  };
}

export interface IUseSources {
  isLoading: boolean;
  sources: Source[];
  getSourceLink: (item: Source) => string;
  getConnectionLink: (item: Source) => string;
  onClickAdd: React.MouseEventHandler;
  onDelete: (item: Source) => Promise<Source>;
  onReSync: (item: Source) => void;
  onReSample: (item: Source) => void;
  onCopy: (item: Source) => void;
  getDependingRecipes: (sourceId: string) => Promise<Recipe[]>;
  onReplaceFile: (item: Source) => void;
}

export function useSources(sourcesUrl: string, setFilterValues: (executions: Source[]) => void): IUseSources {
  const intl = useIntl();
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [version, setVersion] = useState<number>(0);
  const [sources, setSources] = useState<Source[]>([]);
  const getSourceLink = (source: Source) => `${sourcesUrl}/${source.id}`;
  // const getSourceLink = () => `/404`;
  const getConnectionLink = () => `/404`;
  const onClickAdd = () => alert?.show('add a source');
  const reSyncConfirm = intl.formatMessage({ id: 'app.source.reSyncConfirm' });
  const reSampleConfirm = intl.formatMessage({ id: 'app.source.reSampleConfirm' });

  const onDelete = async (item: Source) => {
    const getSources = (s: Source[]) => s.filter(a => a.id !== item.id);
    return new Promise(async resolve => {
      await deleteSource(item.id)
        .then(() => {
          setSources(r => getSources(r));
          resolve(getSources(sources));
        })
        .catch(err => {
          handleError(err, alert);
          return undefined as unknown as Source;
        });
    });
  };

  const onCopy = async (item: Source): Promise<void> => {
    setIsLoading(true);
    setVersion(version + 1);
    saveAddSourceWizardContext(item.id);
    window.location.href = `/${intl.locale}/app/source/add`;
  };
  const onReplaceFile = (item: Source) => {
    removeAddSourceWizardContext();
    const uploadSourceViewUrl = `/${intl.locale}/app/source/${item.id}/upload`;
    window.location.href = uploadSourceViewUrl;
  };

  const onReSync = (item: Source): void => {
    if (item) {
      evictSourceCache(item.id)
        .then(() => alert.info(reSyncConfirm))
        .catch(err => handleError(err, alert));
    }
  };

  const onReSample = (item: Source): void => {
    if (item) {
      refreshSample(item.id)
        .then(() => alert.info(reSampleConfirm))
        .catch(err => handleError(err, alert));
    }
  };

  const getDependingRecipes = (sourceId: string): Promise<Recipe[]> => {
    return getDependingRecipesBySourceId(sourceId);
  };

  useEffect(() => {
    setIsLoading(true);
    listSources()
      .then(sources => {
        const sorted = [...sources];
        sorted.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setSources(sorted);
        setFilterValues(sorted);
        setIsLoading(false);
      })
      .catch(err => {
        handleError(err, alert);
      });
  }, [version]);

  return {
    isLoading,
    sources,
    getSourceLink,
    getConnectionLink,
    onClickAdd,
    onDelete: onDelete as (item: Source) => Promise<Source>,
    onReSync,
    onReSample,
    getDependingRecipes,
    onCopy,
    onReplaceFile,
  };
}

function buildFileSourceReq(
  state: AggregatedAddSourceState | AggregatedUploadFileSourceState | any,
): CreateSourceRequest {
  let connector;
  let dataFormat;
  if (state?.connect) {
    connector = state.connect.selectedType || state.connect.type;
    dataFormat = connector?.toUpperCase();
  } else {
    dataFormat = state.configure.fileType;
    connector = dataFormat?.toLowerCase();
  }
  const { fileId, fileName, nRows, nColumns } = state.upload.upload;
  // const { schema, data } = state.upload.upload.sample;
  const { schema: sampleSchema, data } = state.configure.fullSample || state.configure.sample;
  const { name, description } = state.nameDesc;
  return {
    name: name || fileName,
    description,
    sourceId: state.sourceId,
    id: state.sourceId,
    config: {
      ...state.configure,
      type: `file`,
      connector: connector,
      dataFormat: dataFormat,
      fileId: fileId,
      tabName: state.configure.table,
      tableDisplayName: state.configure.tableDisplayName,
      fileParserProps: {
        delimiter: state.configure.delimiter,
        qualifier: state.configure.qualifier,
        charset: state.configure.charset,
      },
    },
    profile: {
      schema: sampleSchema,
      sample: data,
      rows: nRows,
      columns: nColumns,
    },
  };
}

function buildGeneralSourceReq(state: AggregatedAddSourceState): CreateSourceRequest | undefined {
  const connector = state.connect.selectedType || state.connect.type;
  let connectionId;
  if (state.authenticate?.auth?.id) {
    connectionId = state.authenticate.auth.id;
  } else {
    connectionId = state?.connect?.connection?.id;
  }
  const newVar = state.configure.fullSample || state.configure.sample;
  const sampleSchema = newVar?.schema || state.configure.fields;
  const data = newVar?.data;

  if (sampleSchema) {
    const nColumns = sampleSchema.length;
    const { name, description } = state.nameDesc;
    const isFs = state.configure.fileType || state.configure.folderURL || state.configure.fileURL;
    if (isFs) {
      // file system connection
      const fileURL = state.configure.fileURL;
      const folderURL = state.configure.folderURL;
      const filePattern = state.configure.filePattern;
      const isMultiple = state.configure.isMultiple || (state.configure.folderURL || '') !== '';
      const tabNames = state.configure.tabs;
      const extension = state?.configure?.fileType?.toUpperCase();
      const compression = state.configure.compression;
      let filePath: {
        fileURL?: string;
        folderURL?: string;
        filePattern?: string;
        tabNames?: string[];
        extension?: string;
        compression?: string;
      };
      if (isMultiple) {
        filePath = {
          extension,
          folderURL,
          filePattern,
          tabNames,
        };
      } else {
        filePath = {
          extension,
          fileURL,
          tabNames,
          compression,
        };
      }
      return {
        name,
        description,
        connectionId,
        config: {
          ...state.configure,
          type: `filesystem`,
          connector: connector as string,
          fileType: state.configure.fileType || 'CSV',
          headerMasks: state.configure.headerMasks,
          ...filePath,
        },
        profile: {
          schema: sampleSchema,
          sample: data,
          rows: undefined as unknown as number,
          columns: nColumns,
        },
      };
    } else {
      // object path
      const catalog = state.configure.catalog;
      const schema = state.configure.schema;
      const table = state.configure.table;
      const tableDisplayName = state.configure.tableDisplayName;
      const query = state.configure.query;
      const updateCursor = state.configure.cursorField;
      return {
        name,
        description,
        connectionId,
        config: {
          ...state.configure,
          type: query ? `query` : `connection`,
          connector: connector as string,
          catalog,
          schema,
          table,
          tableDisplayName,
          updateCursor,
          query: query,
        },
        profile: {
          schema: sampleSchema,
          sample: data,
          rows: undefined as unknown as number,
          columns: nColumns,
        },
      };
    }
  }
}
