import { FeatureFlagContextModel } from '@savant-components/builder';
import { useFeatureService } from '../../hooks/feature';

export const useFeatureFlags = (): FeatureFlagContextModel => {
  const { isFeatureEnabled } = useFeatureService();

  return {
    enableAlwaysOnSchedule: isFeatureEnabled('ALWAYS_ON_SCHEDULE'),
    enableBatchLLM: isFeatureEnabled('BATCH_LLM'),
    enableDebugSession: isFeatureEnabled('DEBUG_SESSION'),
    enableMultioutputFilter: isFeatureEnabled('MULTIOUTPUT_FILTER'),
    enableParameterDependencies: isFeatureEnabled('PARAMETER_DEPENDENCIES'),
    enableUndoRedo: isFeatureEnabled('UNDO_REDO'),
    enableDbPushdown: isFeatureEnabled('DB_PUSH_DOWN'),
    enableApiConnectorEnhancements: isFeatureEnabled('API_CONN_ENHANCEMENTS'),
    enableAutoLinkV2: isFeatureEnabled('AUTO_LINK'),
  };
};
