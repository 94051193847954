import client, { followPromise, handleError } from './client';
import { Uploader, UploadRequest, UploadResponse } from '@savant-components/basic';
import { useAlert } from 'react-alert';
import { AxiosRequestConfig } from 'axios';
import { SkipStagingRequest } from '@savant-components/basic/build/Dropzone/Uploader';

const UPLOAD_PROGRESS = 0.8;
const SIZE_1_MB = 1024 * 1024;
const largeFileSizeExtensions = ['csv', 'tsv', 'txt', 'yxmd', 'xlsx', 'xls', 'xlsm', 'xlsb', 'yxmd'];
const imgFileSizeExtensions = ['png', 'jpg', 'jpeg', 'webp'];
class APIUploader implements Uploader {
  discard = async (fileId: string): Promise<void> => {
    await client.delete(`/upload/files/${fileId}`);
    return;
  };
  upload = async ({
    file,
    sample = 1000,
    onProcessUpdate,
    allowAnyExtension = false,
    skipStaging = true,
  }: UploadRequest): Promise<UploadResponse | undefined> => {
    const data = new FormData();
    data.append('file', file);
    data.append('sample', sample.toString());
    const fileSize = file.size; // filesize in bytes

    const fileExt = file.name.slice(file.name.lastIndexOf('.') + 1);
    let allowFileUpload = false;
    if (largeFileSizeExtensions.includes(fileExt) || allowAnyExtension) {
      if (fileSize < 50 * SIZE_1_MB) {
        allowFileUpload = true;
      } else {
        throw Error(`Your file is too big. You can upload up to 50MB for this format type.`);
      }
    } else if (imgFileSizeExtensions.includes(fileExt)) {
      if (fileSize < 10 * SIZE_1_MB) {
        allowFileUpload = true;
      } else {
        throw Error(`Your image is too big. You can upload up to 10MB for this format type.`);
      }
    }
    if (allowFileUpload) {
      allowFileUpload = false;
      const config: AxiosRequestConfig = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        onUploadProgress: function (progressEvent) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          const progress = Math.min(UPLOAD_PROGRESS, percentCompleted * 0.01 * UPLOAD_PROGRESS);
          if (onProcessUpdate) {
            onProcessUpdate(progress);
          }
        },
      };
      return client.post(`/upload/file-async`, data, config).then(function (res) {
        const promise = res.data;
        if (promise.progress == undefined) {
          promise.progress = UPLOAD_PROGRESS;
        }
        promise.createdTime = new Date();
        return followPromise<UploadResponse>(promise, onProcessUpdate).then(result => {
          const fileId = result['fileId'];
          if (skipStaging) {
            const skipStagingRequest: SkipStagingRequest = {
              fileStatus: `PERMANENT`,
            };
            client.patch(`/upload/files/${fileId}`, skipStagingRequest);
          }

          return {
            fileId,
            fileName: result['fileName'],
            fileFormat: result?.['fileFormat']?.toLowerCase() || '',
            size: result['size'],
            nCols: result['nCols'],
            nRows: result['nRows'],
            error: result?.['exception']?.message,
          } as UploadResponse;
        });
      });
    } else throw Error('Error Uploading file');
  };
}

// need useAlert hook
export function useUploader(): Uploader {
  const alert = useAlert();
  const uploader = new APIUploader();
  return {
    discard: async (fileId: string): Promise<void> => {
      return uploader.discard(fileId).catch(err => handleError(err, alert));
    },
    upload: async (req: UploadRequest): Promise<UploadResponse | undefined> => {
      return uploader
        .upload(req)
        .then(res => {
          if (res?.error) {
            alert.error(res.error);
          }
          return res;
        })
        .catch(err => {
          handleError(err, alert);
          return undefined;
        });
    },
  };
}

export default new APIUploader();
