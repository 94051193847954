import { Recipe } from '@savant-components/basic';
import { Connection, Source, getConnDef } from '@savant-components/catalog';

export const getConnectorsArray = (sources: Connection[] | Source[]) => {
  const optionArray: string[] = [];

  sources?.forEach(info => {
    const name = getConnDef(info.type)?.name;
    if (name !== undefined) {
      if (!optionArray.includes(name)) {
        optionArray.push(name);
      }
    }
  });

  return optionArray;
};

export const getOwnerArray = (sources: Connection[] | Source[] | Recipe[]) => {
  const optionArray: string[] = [];

  sources?.forEach(info => {
    if (info.ownerName !== undefined) {
      if (!optionArray.includes(info.ownerName)) {
        optionArray.push(info.ownerName);
      }
    }
  });

  return optionArray;
};

export const parseLoginCode = (code: string): string => {
  return atob(code);
};
