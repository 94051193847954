import { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { emitCustomEvent, useCustomEventListener } from 'react-custom-events';

import {
  AuditReportPayload,
  Execution,
  Recipe,
  Trigger,
  UpdateExecutionMetadataRequest,
} from '@savant-components/catalog';
import { Automation } from '@savant-components/builder';
import { handleError } from '../services/client';
import { getAutomationByRecipeId } from '../services/automation';
import {
  cancelExecution as cancelExec,
  downloadReportExecution,
  getExecution,
  getExecutionsFromExecution,
  getExecutionsInRecipe,
  updateExecutionMetadata as updateExecutionMetadataViaAPI,
} from '../services/execution';
import { useAsync } from '@savant-components/basic';
import { REFRESH_TIMEOUT } from '../constants';
import { getTriggerByRecipeId } from '../services/trigger';
import { AxiosError } from 'axios';
import { getRecipeFromExecution } from '../services/recipes';
import { useQuery } from '@tanstack/react-query';

const REFRESH_SINGLE_EVENT = 'app/runs/refreshSingle';

export function useRuns({
  recipeId,
  setFilterValues,
}: {
  recipeId: string;
  setFilterValues: (executions: Execution[]) => void;
}): {
  isLoading: boolean;
  executions: Execution[];
  cancelExecution: (exec: Execution) => Promise<void>;
  downloadReportExecution: (executions: AuditReportPayload) => Promise<void>;
  getExecutionsFromExecution: (execution: Execution) => Promise<Execution[]>;
  getRecipeFromExecution: (exec: Execution) => Promise<Recipe>;
  updateExecutionMetadata: (req: UpdateExecutionMetadataRequest) => Promise<Execution>;
} {
  const { value, status, error, execute } = useAsync(
    () => getExecutionsInRecipe(recipeId, ['scheduled', 'run_now']),
    false,
  );
  const [executions, setExecutions] = useState<Execution[]>(
    ((value as Execution[]) || []).filter(exec => exec.type !== 'test_run'),
  );
  const [isLoading, setIsLoading] = useState<boolean>(!(status === 'success' || status === 'error'));

  useEffect(() => {
    execute();
  }, [recipeId]);
  const alert = useAlert();
  useEffect(() => {
    if (error) {
      handleError(error as AxiosError, alert);
    }
  }, [error]);

  useEffect(() => {
    if (!isLoading && !(status === 'success' || status === 'error')) {
      setIsLoading(true);
    } else if (isLoading && (status === 'success' || status === 'error')) {
      setIsLoading(false);
      error && handleError(error as AxiosError, alert);
      updateExecutions((error ? [] : value) as Execution[]);
    }
  }, [isLoading, status]);

  function updateExecutions(executions: Execution[]) {
    executions
      .filter(exec => exec.phase.toLowerCase() === 'running' || exec.phase.toLowerCase() === 'pending')
      .forEach(exec =>
        setTimeout(() => {
          emitCustomEvent(REFRESH_SINGLE_EVENT, { executionId: exec.id });
        }, REFRESH_TIMEOUT),
      );
    setFilterValues(executions);
    setExecutions(executions);
  }

  //const isLoading = status !== 'success' && status !== 'error';
  //const executions = ((value as Execution[]) || []).filter(exec => exec.type !== 'test_run');
  const cancelExecution = async (exec: Execution) => {
    return cancelExec(exec.id)
      .then(() => execute())
      .catch(err => handleError(err, alert));
  };

  const updateExecutionMetadata = async (req: UpdateExecutionMetadataRequest): Promise<Execution> => {
    return updateExecutionMetadataViaAPI(req)
      .then(res => {
        execute();
        return res;
      })
      .catch(err => {
        handleError(err, alert);
        return { id: req.id, name: req.name } as Execution;
      });
  };

  // fast partial refresh
  useCustomEventListener(REFRESH_SINGLE_EVENT, (data: { executionId: string }) => {
    const executionId = data.executionId;
    getExecution(executionId).then(exec => {
      setExecutions(
        executions.map(e2 => {
          return e2.id === exec.id ? { ...exec } : e2;
        }),
      );
      if (
        exec.phase.toLowerCase() === 'running' ||
        exec.phase.toLowerCase() === 'pending' ||
        exec.phase.toLowerCase() === 'preparing'
      ) {
        setTimeout(() => {
          emitCustomEvent(REFRESH_SINGLE_EVENT, { executionId });
        }, REFRESH_TIMEOUT);
      }
    });
  });

  return {
    isLoading,
    executions,
    cancelExecution,
    getExecutionsFromExecution: getExecutionsFromExecution(alert),
    downloadReportExecution: downloadReportExecution(alert, REFRESH_SINGLE_EVENT),
    getRecipeFromExecution: getRecipeFromExecution(alert),
    updateExecutionMetadata,
  };
}

export function useSchedules({ recipe }: { recipe: Recipe }): {
  isTriggerLoading: boolean;
  isAutomationLoading: boolean;
  triggers: Trigger[];
  automations: Automation[];
  updateAutomation: (automation: Automation) => void;
  updateTrigger: (trigger: Trigger) => void;
} {
  const recipeId = recipe?.id;
  const [automations, setAutomations] = useState<Automation[]>([]);
  const [triggers, setTriggers] = useState<Trigger[]>([]);

  const {
    data: automationValue,
    status: automationStatus,
    error: automationError,
  } = useQuery({ queryKey: ['automation'], queryFn: () => getAutomationByRecipeId(recipeId) });

  const {
    data: triggerValue,
    status: triggerStatus,
    error: triggerError,
  } = useQuery({ queryKey: ['trigguer'], queryFn: () => getTriggerByRecipeId(recipeId) });

  const alert = useAlert();
  useEffect(() => {
    if (automationError) {
      handleError(automationError as AxiosError, alert);
    }
    if (triggerError) {
      handleError(triggerError as AxiosError, alert);
    }
  }, [automationError]);

  useEffect(() => {
    setTriggers(triggerValue ? (triggerValue as Trigger[]) : []);
  }, [triggerValue]);

  useEffect(() => {
    setAutomations(automationValue ? [automationValue as Automation] : []);
  }, [automationValue]);

  const isTriggerLoading = triggerStatus !== 'success' && triggerStatus !== 'error';
  const isAutomationLoading = automationStatus !== 'success' && automationStatus !== 'error';

  return {
    isTriggerLoading,
    isAutomationLoading,
    triggers,
    automations,
    updateAutomation: (automation: Automation) => {
      let exist = false;
      let automations2 = automations.map(a => {
        if (a.id === automation.id) {
          exist = true;
          return automation;
        } else {
          return a;
        }
      });
      if (!exist) {
        automations2 = [automation, ...automations2];
      }
      setAutomations(automations2);
    },
    updateTrigger: (trigger: Trigger) => {
      let exist = false;
      let triggers2 = triggers.map(a => {
        if (a.triggerId === trigger.triggerId) {
          exist = true;
          return trigger;
        } else {
          return a;
        }
      });
      if (!exist) {
        triggers2 = [trigger, ...triggers2];
      }
      setTriggers(triggers2);
    },
  };
}
