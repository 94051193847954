import { SessionPermission } from '../permission';
import { TabSession } from '../types';

export type Feature =
  | 'SEE_ANALYSIS_PAGE'
  | 'SEE_DATA_PAGE'
  | 'SEE_RUNS_PAGE'
  | 'SEE_ADMIN_PAGE'
  | 'SEE_BUILDER_PAGE'
  | 'SEE_LINK_PAGE'
  | 'SEE_BIZAPP_PAGE'
  | 'SEE_WORKSPACES_TAB'
  | 'SEE_COMMUNITY_TAB'
  // >>>>> canvas >>>>>
  | 'AUTO_LINK'
  | 'UNDO_REDO'
  | 'DB_PUSH_DOWN'
  // >>>>> connectors >>>>>
  | 'CONNECTOR_GOOGLECAMPAIGNMANAGER'
  | 'CONNECTOR_AZURE_SYNAPSE'
  | 'CONNECTOR_TWITTER'
  // <<<<< connectors <<<<<
  | 'MODULE_DATA_SERVICE'
  | 'INSTALL_TEMPLATE'
  | 'CREATE_TEMPLATE'
  // <<<<< bot <<<<<
  | 'ALWAYS_ON_SCHEDULE'
  // <<<<< admin <<<<<
  | 'DEBUG_SESSION'
  | 'PARAMETER_DEPENDENCIES'
  | 'TRANSFORM_V2'
  // <<<<< analytic tools <<<<<
  | 'XML_TOOL'
  | 'MULTIOUTPUT_FILTER'
  | 'BATCH_LLM'
  | 'API_CONN_ENHANCEMENTS'
  // <<<<< others <<<<<
  | 'EMAIL_TRIGGER'
  | 'DOWNLOAD_REPORT'
  | 'WEBHOOK_TRIGGER'
  | 'PURGING_EXECUTIONS';

export function isFeatureEnabled(session: TabSession, feature: Feature): boolean {
  let enabled = false;
  const permission: SessionPermission = session?.permission;
  if (permission) {
    switch (feature) {
      case 'SEE_BIZAPP_PAGE':
        enabled = true;
        break;
      // >>>>> Account Permissions >>>>>
      case 'SEE_ANALYSIS_PAGE':
      case 'SEE_DATA_PAGE':
      case 'SEE_RUNS_PAGE':
      case 'SEE_BUILDER_PAGE':
      case 'SEE_ADMIN_PAGE':
      case 'SEE_LINK_PAGE':
      case 'SEE_COMMUNITY_TAB':
        // let account permission populate for one week
        enabled = permission.account.ANALYST_PAGES === undefined ? true : permission.account.ANALYST_PAGES;
        break;
      case 'SEE_WORKSPACES_TAB':
        enabled = permission.account.WORKSPACES_ADMIN_TAB || false;
        break;
      case 'ALWAYS_ON_SCHEDULE':
        enabled = permission.account.ALWAYS_ON_SCHEDULE || false;
        break;
      case 'DEBUG_SESSION':
        enabled = permission.account.EXPLORATION_MODE || false;
        break;
      case 'CREATE_TEMPLATE':
        enabled = permission.account.CREATE_TEMPLATE || false;
        break;
      // <<<<< Account Permissions <<<<<
      // >>>>> Feature Flags >>>>>
      case 'CONNECTOR_GOOGLECAMPAIGNMANAGER':
      case 'CONNECTOR_AZURE_SYNAPSE':
      case 'PARAMETER_DEPENDENCIES':
      case 'DB_PUSH_DOWN':
      case 'UNDO_REDO':
      case 'AUTO_LINK':
        enabled = permission.experiment[feature] || false;
        break;
      // <<<<< Feature Flags <<<<<
      // >>>>> expired flags >>>>>
      case 'INSTALL_TEMPLATE':
      case 'MULTIOUTPUT_FILTER':
      case 'EMAIL_TRIGGER':
      case 'WEBHOOK_TRIGGER':
      case 'DOWNLOAD_REPORT':
      case 'BATCH_LLM':
      case 'API_CONN_ENHANCEMENTS':
      case 'PURGING_EXECUTIONS':
      case 'CONNECTOR_TWITTER':
        enabled = true;
        break;
      // <<<<< expired flags <<<<<
      default:
        enabled = false;
    }
  }

  return enabled;
}
