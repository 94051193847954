import React from 'react';
import { Spacer } from '@savant-components/basic';

import UserMenu from './UserMenu';
import OrgMenu from './OrgMenu';
import { getSession, getTabSession } from '../../services/storage';

const SessionControl = (): JSX.Element => {
  const session = getSession();
  const tab = getTabSession();
  const user = session?.user;
  const org = tab?.organization;
  return (
    <React.Fragment>
      {org && <OrgMenu tab={tab} />}
      {org && user && <Spacer direction="horizontal" size="8px" />}
      {user && <UserMenu />}
    </React.Fragment>
  );
};

export default SessionControl;
