/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { useAlert } from 'react-alert';

import {
  Connection,
  AuthenticateStepState,
  AggregatedAddConnectionState,
  AggregatedReAuthConnectionState,
  BasicAuthRequest,
  SshAuthRequest,
  DbAuthRequest,
  ApiKeyAuthRequest,
  OAuthRequest,
  AwsAuthRequest,
  AuthenticationSummary,
  Connector,
  Recipe,
} from '@savant-components/catalog';
import { ConnectionInfo, WizardStep } from '@savant-components/basic';

import { saveRedirectState, getRedirectState, removeRedirectState } from '../services/storage';
import {
  deletConnection,
  listConnections,
  redirectOAuth,
  updateConnection,
  RedirectOAuthRequest,
  UpdateConnectionRequest,
  authenticateDb,
  authenticateSsh,
  authenticateApiKey,
  authenticateCustomOAuth as authCustomOAuth,
  authenticateBasicAuth as authBasicAuth,
  authenticateAwsAuth as authAwsAuth,
  authenticateGenericApi as authGenericApi,
  authenticateGoogleSAAuth as authGoogleSA,
  getConnection,
  getConnectionInfo as getConnectionInfoViaApi,
} from '../services/connection';
import { handleError } from '../services/client';
import { GenericApiRequest, GoogleSARequest } from '@savant-components/catalog';
import { getDependingRecipesByConnectionId } from '../services/recipes';

interface IUseConnectionDetail {
  isLoading: boolean;
  connection?: Connection;
}

export function useConnectionDetail(connectionId: string): IUseConnectionDetail {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [connection, setConnection] = useState<Connection>();
  const alert = useAlert();
  useEffect(() => {
    if (connectionId !== connection?.id && !isLoading) {
      setIsLoading(true);
      if (connection) {
        setConnection(undefined);
      }
      getConnection(connectionId)
        .then(conn => {
          setConnection(conn);
          setIsLoading(false);
        })
        .catch(err => handleError(err, alert));
    }
  }, [connectionId, isLoading, connection?.id]);
  return {
    isLoading,
    connection,
  };
}

function parseAuthError(err: AxiosError): string {
  if (err['response']) {
    if (typeof err['response'].data === 'string') {
      return err['response'].data;
    } else {
      let msg = err['response'].data.error_detail;
      if (err['response'].data.cause) {
        msg += ':\n' + err['response'].data.cause;
      }
      return msg;
    }
  } else {
    return err.message;
  }
}

export interface IAddConnection {
  isLoading: boolean;
  isRedirecting: boolean;
  initialType?: string;
  restoreSteps: WizardStep[];
  saveAndAuthenticate: (steps: WizardStep[], authStep: AuthenticateStepState) => Promise<void>;
  authenticateBasicAuth: (
    connector: Connector,
    connectionId: string,
    client: BasicAuthRequest,
  ) => Promise<AuthenticationSummary>;
  authenticateCustomOAuth: (
    connector: Connector,
    connectionId: string,
    client: OAuthRequest,
  ) => Promise<AuthenticationSummary>;
  authenticateGenericApiAuth: (
    connector: Connector,
    connectionId: string,
    client: GenericApiRequest,
  ) => Promise<AuthenticationSummary>;
  authenticateSshAuth: (
    connector: Connector,
    connectionId: string,
    client: SshAuthRequest,
  ) => Promise<AuthenticationSummary>;
  authenticateDbAuth: (
    connector: Connector,
    connectionId: string,
    client: DbAuthRequest,
  ) => Promise<AuthenticationSummary>;
  authenticateApiKeyAuth: (
    connector: Connector,
    connectionId: string,
    client: ApiKeyAuthRequest,
  ) => Promise<AuthenticationSummary>;
  authenticateAwsAuth: (
    connector: Connector,
    connectionId: string,
    client: AwsAuthRequest,
  ) => Promise<AuthenticationSummary>;
  authenticateGoogleSAAuth: (
    connector: Connector,
    connectionId: string,
    client: GoogleSARequest,
  ) => Promise<AuthenticationSummary>;
  confirmAddConnection: (state: AggregatedAddConnectionState) => Promise<Connection>;
  confirmReAuthConnection: (connectionId: string, state: AggregatedReAuthConnectionState) => Promise<Connection>;
}

export function useAddConnection(): IAddConnection {
  const alert = useAlert();

  let qString = '';
  if (typeof window !== 'undefined') {
    qString = window.location.search;
  }
  const restoreSteps = useMemo(() => {
    if (qString) {
      const parsed = queryString.parse(qString);
      if (parsed.restoreState !== undefined) {
        const error_detail = parsed.error_detail;
        if (error_detail) {
          const redirectState = getRedirectState();
          return redirectState.map((step: any) => {
            if (step.id === 'authenticate') {
              return {
                ...step,
                state: {
                  ...step.state,
                  auth: {
                    error: error_detail,
                  },
                },
              };
            } else {
              return step;
            }
          });
        } else {
          const connectionId = parsed.connectionId;
          const externalId = parsed.externalId;
          const externalName = parsed.externalName;
          const expiresAt = parseInt(parsed.expiresAt as string);
          const redirectState = getRedirectState();
          return redirectState.map((step: any) => {
            if (step.id === 'authenticate') {
              return {
                ...step,
                state: {
                  ...step.state,
                  auth: {
                    id: connectionId,
                    externalId,
                    externalName,
                    expiresAt,
                  },
                },
              };
            } else {
              return step;
            }
          });
        }
      } else {
        removeRedirectState();
        return undefined;
      }
    }
  }, [qString]);

  const initialType = useMemo(() => {
    if (qString) {
      const parsed = queryString.parse(qString);
      return parsed.type as string;
    } else {
      return undefined;
    }
  }, [qString]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);
  const [version, setVersion] = useState<number>(0);

  const saveAndAuthenticate = async (steps: WizardStep[], authState: AuthenticateStepState): Promise<void> => {
    setIsRedirecting(true);
    saveRedirectState(steps);
    let returnUrl = window?.location?.href || '';
    returnUrl = returnUrl.split('?')[0];
    const req: RedirectOAuthRequest = {
      connector: authState.connector,
      externalId: authState.externalId,
      environment: (authState as any)?.environment,
      clientId: (authState as any)?.clientId,
      clientSecret: (authState as any)?.clientSecret,
      agencyId: authState.agencyId,
      advertiserId: authState.advertiserId,
      warehouse: authState.warehouse,
      database: authState.database,
      role: authState.role,
      returnUrl,
    };
    return redirectOAuth(req)
      .then(redirectUrl => {
        window.location.href = redirectUrl;
      })
      .catch(err => {
        handleError(err, alert);
      });
  };

  const authenticateBasicAuth = async (
    connector: Connector,
    _: string,
    client: BasicAuthRequest,
  ): Promise<AuthenticationSummary> => {
    return authBasicAuth({
      connector,
      client,
    })
      .then(conn => {
        return {
          ...client,
          id: conn.id,
          externalName: conn.externalName,
          expiresAt: conn.expiresAt || 0,
        };
      })
      .catch(err => {
        throw Error(parseAuthError(err));
      });
  };

  const authenticateDbAuth = (
    connector: Connector,
    _: string,
    client: DbAuthRequest,
  ): Promise<AuthenticationSummary> => {
    return authenticateDb({
      connector,
      client,
    })
      .then(conn => {
        return {
          id: conn.id,
          server: client.server,
          port: client.port,
          database: client.database,
          username: client.username,
          useSSL: client.useSSL,
          expiresAt: conn.expiresAt || 0,
        };
      })
      .catch(err => {
        throw Error(parseAuthError(err));
      });
  };

  const authenticateCustomOAuth = (
    connector: Connector,
    _: string,
    client: OAuthRequest,
  ): Promise<AuthenticationSummary> => {
    return authCustomOAuth({
      connector,
      client,
    })
      .then(conn => {
        return {
          ...client,
          id: conn.id,
          externalName: conn.externalName,
          expiresAt: conn.expiresAt || 0,
        };
      })
      .catch(err => {
        throw Error(parseAuthError(err));
      });
  };

  const authenticateGenericApiAuth = (
    connector: Connector,
    _: string,
    client: GenericApiRequest,
  ): Promise<AuthenticationSummary> => {
    return authGenericApi({
      connector,
      client,
    })
      .then(conn => {
        return {
          ...client,
          id: conn.id,
          externalName: conn.externalName,
          expiresAt: conn.expiresAt || 0,
        };
      })
      .catch(err => {
        throw Error(parseAuthError(err));
      });
  };

  const authenticateSshAuth = (
    connector: Connector,
    _: string,
    client: SshAuthRequest,
  ): Promise<AuthenticationSummary> => {
    return authenticateSsh({
      connector,
      client,
    })
      .then(conn => {
        return {
          id: conn.id,
          server: client.server,
          port: client.port,
          username: client.username,
          expiresAt: conn.expiresAt || 0,
        };
      })
      .catch(err => {
        throw Error(parseAuthError(err));
      });
  };

  const authenticateApiKeyAuth = (
    connector: Connector,
    _: string,
    client: ApiKeyAuthRequest,
  ): Promise<AuthenticationSummary> => {
    return authenticateApiKey({
      connector,
      client,
    })
      .then(conn => {
        return {
          ...client,
          id: conn.id,
          externalName: conn.externalName,
          expiresAt: conn.expiresAt || 0,
        };
      })
      .catch(err => {
        throw Error(parseAuthError(err));
      });
  };

  const authenticateAwsAuth = (
    connector: Connector,
    _: string,
    client: AwsAuthRequest,
  ): Promise<AuthenticationSummary> => {
    return authAwsAuth({
      connector,
      client,
    })
      .then(conn => {
        return {
          ...client,
          id: conn.id,
          externalName: conn.externalName,
          expiresAt: conn.expiresAt || 0,
        };
      })
      .catch(err => {
        throw Error(parseAuthError(err));
      });
  };

  const authenticateGoogleSAAuth = (
    connector: Connector,
    _: string,
    client: GoogleSARequest,
  ): Promise<AuthenticationSummary> => {
    return authGoogleSA({
      connector,
      client,
    })
      .then(conn => {
        return {
          ...client,
          id: conn.id,
          externalName: conn.externalName,
          expiresAt: conn.expiresAt || 0,
        };
      })
      .catch(err => {
        throw Error(parseAuthError(err));
      });
  };

  const confirmAddConnection = (state: AggregatedAddConnectionState): Promise<Connection> => {
    setIsLoading(true);
    return new Promise(resolve => {
      const { id } = state.authenticate.auth;
      const { name, description } = state.authenticate;
      const req: UpdateConnectionRequest = {
        name: name || '',
        description: description || '',
      };
      updateConnection(id, req)
        .then(conn => {
          setVersion(version + 1);
          resolve(conn);
        })
        .catch(err => {
          handleError(err, alert);
        });
    });
  };

  const confirmReAuthConnection = (
    connectionId: string,
    state: AggregatedReAuthConnectionState,
  ): Promise<Connection> => {
    setIsLoading(true);
    return new Promise(resolve => {
      const { id: authId } = state.authenticate.auth;
      const { name, description } = state.authenticate;
      const req: UpdateConnectionRequest = {
        authId,
        name: name || '',
        description: description || '',
      };
      updateConnection(connectionId, req)
        .then(conn => {
          setVersion(version + 1);
          resolve(conn);
        })
        .catch(err => {
          handleError(err, alert);
        });
    });
  };

  return {
    isLoading,
    isRedirecting,
    initialType,
    restoreSteps,
    confirmAddConnection,
    confirmReAuthConnection,
    saveAndAuthenticate,
    authenticateBasicAuth,
    authenticateApiKeyAuth,
    authenticateDbAuth,
    authenticateAwsAuth,
    authenticateGoogleSAAuth,
    authenticateSshAuth,
    authenticateCustomOAuth,
    authenticateGenericApiAuth,
  };
}

export interface IUseConnections {
  isLoading: boolean;
  connections: Connection[];
  getConnectionLink: (item: Connection) => string;
  getConnectionInfo: (connectionId: string) => Promise<ConnectionInfo>;
  onDelete: (item: Connection) => Promise<Connection>;
  getDependingRecipes: (connectionId: string) => Promise<Recipe[]>;
}

const fileOutput: Connection = {
  id: 'csv',
  name: 'CSV',
  type: 'csv',
  createdTime: undefined,
  createdBy: undefined,
  externalId: undefined,
  expiresAt: undefined,
  status: 'Active',
};

export function useConnections({
  includeFileOutput,
  setFilterValues,
}: {
  includeFileOutput?: boolean;
  connecionsUrl: string;
  setFilterValues?: (recipes: Connection[]) => void;
}): IUseConnections {
  const alert = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [version, setVersion] = useState<number>(0);
  const [connections, setConnections] = useState<Connection[]>([]);

  const getConnectionLink = () => `/404`;

  const onDelete = async (item: Connection) => {
    const getConnections = (s: Connection[]) => s.filter(a => a.id !== item.id);
    return new Promise(async resolve => {
      await deletConnection(item.id)
        .then(() => {
          setConnections(r => getConnections(r));
          setVersion(prev => prev);
          resolve(getConnections(connections));
        })
        .catch(err => {
          handleError(err, alert);
          return undefined as unknown as Connection;
        });
    });
  };

  const getConnectionInfo = (connectionId: string): Promise<ConnectionInfo> => {
    return getConnectionInfoViaApi(connectionId).catch(err => {
      handleError(err, alert);
      return undefined as unknown as ConnectionInfo;
    });
  };

  const getDependingRecipes = (connectionId: string): Promise<Recipe[]> => {
    return getDependingRecipesByConnectionId(connectionId);
  };

  useEffect(() => {
    setIsLoading(true);
    listConnections()
      .then(connections => {
        const sorted = [...connections];
        sorted.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        if (includeFileOutput) {
          setConnections([fileOutput, ...sorted]);
        } else {
          setConnections(sorted);
        }
        setFilterValues && setFilterValues(sorted);
        setIsLoading(false);
      })
      .catch(err => {
        handleError(err, alert);
      });
  }, [version]);

  return {
    isLoading,
    connections,
    getConnectionLink,
    getConnectionInfo,
    onDelete: onDelete as (item: Connection) => Promise<Connection>,
    getDependingRecipes,
  };
}
